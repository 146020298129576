import React from "react";

const config = require("../../../config.json")
const client = config.APP_CLIENT;

const PhoneLink = {
  display: "flex",
  alignItems: "center",
  textDecoration: "none",
  color: "#4b5264",
  paddingRigth: "5px",
  marginRigth: "5px",
  lineHeight: "0",
};
const imgSocial = {
  paddingRight: "10px",
  paddingBottom: '10px',
  marginTop: '10px'
};
const imgSocialEco = {
  paddingRight: "10px",
  objectFit: "contain",
  height: "100%"
};
const container = {
  display: 'flex',
  alignItems: "center"
}

export default function Linkedin(linkedin) {
  return (
    <p style={container}>
      <a href={`${linkedin.linkedin}`}
        style={PhoneLink}
        target="_blank"
      >
        <img style={imgSocial} src={`https://files.myclientisrich.com/${client}/linkedin.jpg`} width="30" height="30" alt="logo" />
      </a>
        <img style={imgSocialEco} src={`https://files.myclientisrich.com/${client}/ecovadis.jpg`} alt="logo" width="50" height="50" />
    </p>
  );
}
