import React from "react";
const config = require("../../../config.json");
const compagnyColorPrimary = config.APP_COMPAGNYCOLORPRIMARY;

const iconLogo = {
  marginRight: "10px",
  transform: "translateY(5px)",
};
const PhoneStyle = {
  fontSize: "15px",
  color: compagnyColorPrimary,
  margin: "0px 0px 3px",
};
const PhoneLink = {
  fontSize: '14px',
  textDecoration: 'none',
  color: '#4b5264',
  fontWeight: '700'
};

function telWithoutSpaces(number) {
  return number.replace(/\s/g, "");
}

export default function Phone({ phone, lang }) {

  console.log(lang);

  return (
    <p style={PhoneStyle}>
      {/* <img src={`https://files.myclientisrich.com/${client}/phone.png`} style={iconLogo} alt="logo" width="19" height="20"></img> */}
      <a href={`tel:${telWithoutSpaces(phone)}`}
        style={PhoneLink}
      >
        {lang == 'FR' ? 'Tel': 'Phone'}: {phone}
      </a>
      <br />
    </p>
  );
}
